import request from '@/utils/request';

export function calculate(data) {
  return request({
    url: '/api/guaratee/calculation/calculate',
    method: 'post',
    data,
  });
}

export function amount(params) {
  return request({
    url: '/api/external/yanCheng/amount',
    method: 'get',
    params,
  });
}

export function count(params) {
  return request({
    url: '/api/external/yanCheng/count',
    method: 'get',
    params,
  });
}

export default { calculate, amount, count };
