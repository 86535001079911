<template>
  <div class="index">
    <img
      src="../../assets/image/banner@2x.png"
      class="bg"
      alt=""
    >
    <div class="three">
      <div class="tab1">
        <div>{{ countNum.accCustomerConut }}</div><span>已服务用户（人）</span>
      </div>
      <div class="tab2">
        <div>{{ countNum.countSumAmount }}</div><span>担保金额（元）</span>
      </div>
      <div class="tab3">
        <div>{{ countNum.countNumber }}</div><span>已出函（笔）</span>
      </div>
    </div>

    <div class="calculate">
      <img
        class="calculateBG"
        src="../../assets/image/bg_baofeigaisuan@2x.png"
        alt=""
      >
      <div class="box">
        <div class="premium">
          <div class="color" />
          <img
            src="../../assets/image/pic_title@2x.png"
            alt=""
          >
          <div class="main">
            <div class="form">
              <div>保函类型</div>
              <a-select
                ref="select"
                v-model="form.type"
                style="width: 310px;"
                placeholder="请选择保函类型"
              >
                <a-select-option value="1">
                  投标保函
                </a-select-option>
                <!-- <a-select-option value="2">
                  履约保函
                </a-select-option> -->
              </a-select>
            </div>
            <div class="form">
              <div>保证期限(天)</div>
              <a-input
                v-model="form.time"
                placeholder="请输入保证期限"
                :style="inputCSS"
              />
            </div>
            <div class="form">
              <div>保证金额(元)</div>
              <a-input
                v-model="form.money"
                placeholder="请输入保证金额"
                :style="inputCSS"
              />
            </div>
            <div
              class="btn"
              @click="getAmount"
            >
              保费概算
            </div>
            <div class="Budget">
              <span class="text">金额概算(元)</span>
              <div class="money">
                {{ amountNum }}
              </div>
              <span class="tip">*实际需支付金额以实际审核后显示为准</span>
            </div>
          </div>
        </div>
        <div class="title">
          <span class="text">专业办理「电子保函业务」</span>
          <div
            class="btn"
            @click="goPage"
          >
            保函申请
          </div>
        </div>
      </div>

      <div class="title" />
    </div>
    <div class="steps">
      <img
        class="bg"
        src="../../assets/image/bg_liucheng@2x.png"
        alt=""
      >
      <div class="step">
        <span class="text">仅需3步，即可出函</span>
        <div class="stepImg">
          <img
            class="img1"
            src="../../assets/image/pic01@2x.png"
            alt=""
          >
          <img
            class="img2"
            src="../../assets/image/line@2x.png"
            alt=""
          >
          <img
            class="img1"
            src="../../assets/image/pic02@2x.png"
            alt=""
          >
          <img
            class="img2"
            src="../../assets/image/line@2x.png"
            alt=""
          >
          <img
            class="img1"
            src="../../assets/image/pic03@2x.png"
            alt=""
          >
        </div>
        <div class="stepText">
          <div>填写申请资料</div>
          <div>支付保费</div>
          <div>下载保函</div>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="title">
        关于我们
      </div>
      <div class="under">
        <img
          class="img2"
          src="../../assets/image/bg_about_left@2x.png"
          alt=""
        >
        <img
          class="img3"
          src="../../assets/image/bg_about_right@2x.png"
          alt=""
        >
      </div>
      <div class="main">
        <img
          class="img1"
          src="../../assets/image/pic_yinhao@2x.png"
          alt=""
        >
        <span>{{ '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' }}江苏龙之宝担保有限公司于2022年7月份成立，注册资本为人民币1.06亿元。公司主营投标担保、工程履约担保、工程支付担保、预付款担保、诉讼保全担保、财产保全担保、尾付款如约偿付担保、原材料赊购担保、设备分期付款担保、租赁合同担保、财政支付担保、联合担保、仓储监管担保、其他经济合同担保等各类非融资性担保业务以及与担保业务有关的投融资咨询和财务顾问等中介服务。
          <br>{{ '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' }}公司经营管理队伍强大，现有在册员工8人，全部具有大学以上学历，高级职称1人，中级职称3人，主要经营管理人员均从事金融和管理工作多年，有丰富的团队和业务管理经验。
          <br>{{ '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' }}公司成立以来，以竭诚为盐城及周边地区中小微企业提供方便、快捷、优质、高效的担保服务为宗旨，目前已经接入盐城市招投标担保服务平台，其他各种担保业务均已开展，公司坚持提升服务质量，树立良好的口碑，为盐城的经济发展贡献一份力量。
        </span>
        <!-- <span class="more">[查看更多]</span> -->
      </div>
      <div class="partner">
        <!-- 合作伙伴 -->
      </div>
      <!-- <div class="partnerImg">
        <img
          src="../../assets/img/logo_baiyun@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_chengdu@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_guangda@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_guoyuan@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_haier@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_hengtai@2x.png"
          alt=""
        >
        <br>
        <img
          src="../../assets/img/logo_nanning@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_nonghang@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_wanxiang@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_yangguang@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_yuecai@2x.png"
          alt=""
        >
        <img
          src="../../assets/img/logo_zhongrong@2x.png"
          alt=""
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { amount, count } from '@/api/index';
export default {
  data() {
    return {
      inputCSS: 'width: 310px;height: 48px;border: 1px solid #CCB69B;background: #FFFBF4;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #B2936C;',
      form: {
        type: '1',
      },
      countNum: {},
      amountNum: '---',
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      count().then(res => {
        console.log(res);
        this.countNum = res.data;
      });
    },
    getAmount() {
      console.log(this.form.money);
      amount({ amount: this.form.money }).then(res => {
        console.log(res);
        this.amountNum = res.data;
      });
    },
    goPage() {
      this.$router.push({ path: '/insure/project/view' });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  .bg {
    width: 100%;
    height: 580px;
    background: url('../../assets/image/banner@2x.png') no-repeat;
    background-size: 100%;
  }
  .three {
    width: 100%;
    height: 382px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab1 {
      background: url('../../assets/image/bg_fuwuyonghu@2x.png') no-repeat;
      background-size: 100%;
    }
    .tab2 {
      background: url('../../assets/image/bg_danbaojine@2x.png') no-repeat;
      background-size: 100%;
      margin: 0 50px;
    }
    .tab3 {
      background: url('../../assets/image/bg_chuhan@2x.png') no-repeat;
      background-size: 100%;
    }
    > div {
      width: 360px;
      height: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div {
        font-size: 48px;
        font-family: DIN Medium;
        font-weight: 400;
        color: #333333;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .calculate {
    width: 100%;
    position: relative;
    .calculateBG {
      height: 520px;
      width: 100%;
      background-size: 100%;
      // position: absolute;
    }
    .box {
      width: 1180px;
      height: 590px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .text {
          font-size: 48px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #FFFFFF;
        }
        .btn {
          width: 160px;
          height: 54px;
          background: linear-gradient(90deg, #BE8E51, #DBBD8E);
          border-radius: 27px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 70px;
          cursor: pointer;
        }
      }
    }
    .premium {
      width: 480px;
      height: 590px;
      background: #FFFBF4;
      box-shadow: 0px 0px 20px 0px rgba(187,137,92,0.2);
      z-index: 2;
      .color {
        width: 100%;
        height: 5px;
        background: linear-gradient(90deg, #BE8E51, #DBBD8E);
      }
      img {
        width: 448px;
        height: 40px;
        margin: 15px 0 30px 30px;
      }
      .main {
        padding: 0 30px;
        .form {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          > div {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          /deep/ .ant-select-selection--single {
            height: 48px;
            border: 1px solid #CCB69B;
            background: #FFFBF4;
          }
          /deep/ .ant-select-selection__rendered {
            height: 48px;
            line-height: 48px;
            color: #B2936C;
          }
          /deep/ .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #B2936C;
          }
          input::input-placeholder{
            color: #B2936C;
          }
          input::-webkit-input-placeholder{	//兼容WebKit browsers（Chrome的内核）
            color: #B2936C;
          }
          input::-moz-placeholder{			//Mozilla Firefox 4 to 18
            color: #B2936C;
          }
          input::-moz-placeholder{			//Mozilla Firefox 19+
            color: #B2936C;
          }
          input::-ms-input-placeholder{		//Internet Explorer 10+
            color: #B2936C;
          }
        }
        .btn {
          height: 60px;
          background: linear-gradient(90deg, #BE8E51, #DBBD8E);
          border-radius: 30px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          cursor: pointer;
        }
        .Budget {
          height: 140px;
          border: 2px solid #D9C6AE;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .text {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          .money {
            font-size: 32px;
            font-family: DIN Medium;
            font-weight: 400;
            color: #D83B3B;
            margin: 5px 0;
          }
          .tip {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #AF834E;
          }
        }
      }
    }
  }
  .steps {
    position: relative;
    height: 480px;
    .bg {
      background-size: 100%;
      height: 480px;
      width: 100%;
      position: absolute;
      z-index: -1;
    }
    .step {
      height: 480px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .stepImg {
        width: 987px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 68px 0 20px;
        .img1 {
          width: 120px;
          height: 130px;
        }
        .img2 {
          width: 270px;
          height: 30px;
        }
      }
      .stepText {
        width: 987px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 120px;
          text-align: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin: 80px 0 100px;
    }
    .under {
      position: relative;
      .img2 {
        position: absolute;
        width: 530px;
        height: 220px;
        top: 204px;
        right: 108px;
        z-index: 0;
      }
      .img3 {
        position: absolute;
        width: 390px;
        height: 450px;
        top: -40px;
        left: 260px;
        z-index: 0;
      }
    }
    .main {
      width: 980px;
      // height: 320px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(95,70,47,0.1);
      padding: 56px 66px;
      position: relative;
      z-index: 1;
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
      .more {
        color: #A9793A;
        cursor: pointer;
      }
      .img1 {
        position: absolute;
        width: 136px;
        height: 110px;
        top: -75px;
        left: -105px;
        z-index: 2;
      }

    }

    .partner {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin: 190px 0 35px;
    }
    .partnerImg {
      margin-bottom: 50px;
      img {
        width: 196px;
        height: 110px;
      }

    }
  }
}

</style>
